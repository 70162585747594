/*App*/
export var FONT_FAMILY = 'Roboto, Public Sans, sans-serif';
export var TEST_TOKEN_TEXT = 'The rapid advancement of technology has dramatically transformed the way we live and work. From smartphones to artificial intelligence, these innovations have not only made our lives more convenient but have also reshaped industries across the globe. As businesses adapt to this new digital landscape, the need for continuous learning and upskilling has never been more critical. Those who embrace change and keep pace with technological advancements will be well-positioned for future success, while those who resist may find themselves struggling to remain competitive in an increasingly dynamic world.';
export var CHANGE_LOG_ENTRIES = [
    {
        version: '1.0.0',
        date: 'October 2024',
        description: 'Initial Release of Token Quotas; Basic token counting functionality; User-friendly interface with responsive design.',
    },
];
export var HELP_LINKS = [
    {
        url: 'https://platform.openai.com/docs/concepts',
        title: 'OpenAI Documentation',
    },
    {
        url: 'https://platform.openai.com/tokenizer',
        title: 'OpenAI Tokenizer',
    },
    {
        url: 'https://www.ibm.com/docs/en/watsonx/saas?topic=solutions-tokens',
        title: 'IBM WhatIs Tokenization',
    },
];
export var LANGUAGE_MODELS = [
    {
        value: 1,
        label: 'GPT-4',
        encoding: 'gpt-4-turbo',
        description: 'GPT-4 is the fourth iteration of OpenAI’s Generative Pre-trained Transformer (GPT) series. It is a state-of-the-art language model that can generate human-like text based on the input it receives. GPT-4 has 1 trillion parameters and is capable of performing a wide range of natural language processing tasks.',
        tokenLimit: 8192,
    },
    {
        value: 2,
        label: 'GPT-4 Turbo',
        encoding: 'gpt-4-turbo',
        description: "Represents OpenAI's GPT-4 Turbo model, the successor to GPT-3 with 1 trillion parameters",
        tokenLimit: 8192,
    },
    {
        value: 3,
        label: 'GPT-3.5',
        encoding: 'gpt-3.5-turbo',
        description: 'GPT-3.5 is the third iteration of OpenAI’s Generative Pre-trained Transformer (GPT) series. It is a powerful language model that can generate human-like text based on the input it receives. GPT-3.5 has 175 billion parameters and is capable of performing a wide range of natural language processing tasks.',
        tokenLimit: 4096,
    },
    {
        value: 4,
        label: 'Davinci',
        encoding: 'text-davinci-003',
        description: "Represents GPT-3's Davinci model, one of the most capable models in the GPT-3 series for tasks that require a deep understanding of language",
        tokenLimit: 4096,
    },
    {
        value: 5,
        label: 'Babbage',
        encoding: 'text-babbage-001',
        description: "Represents GPT-3's Babbage model, optimized for tasks that require a more focused and concise response",
        tokenLimit: 4096,
    },
];
export var BOOLEAN_CHECKBOX_OPTIONS = [
    { value: 1, label: 'Yes' },
    { value: 0, label: 'No' },
];
export var HOWTO_STEPS = [
    {
        title: 'Step 1',
        text: 'Select the language model that you want to use for token counting. Different models may count tokens differently.',
    },
    {
        title: 'Step 2',
        text: 'Enter the text you want to analyze in the input field labeled "Text".',
    },
    {
        title: 'Step 3',
        text: 'Click the "Analyze" button to calculate the token count and other data about the text you entered.',
    },
];
export var CAREER_EVENTS = [
    {
        organization: 'Microsoft',
        year: '2022',
        month: 'August',
        title: 'Software Engineer II',
        description: 'I was promoted to Software Engineer II after demonstrating strong technical skills and leadership qualities. Led the development of a new feature for the company’s flagship product, collaborating with cross-functional teams to deliver high-quality software on schedule. Received positive feedback from stakeholders and customers for the successful launch of the feature.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/windows-icon.png',
    },
    {
        organization: 'Microsoft',
        year: '2020',
        month: 'April',
        title: 'Software Engineer I',
        description: 'I worked on the CFS Statutory Compliance team where I was responsible for developing and maintaining the CFS (Common Financial Services) application. The application is a large scale .NET application that is used by all of Microsoft’s financial services teams. I worked on a variety of different technologies including .NET Core, Angular, Azure Services, and much more. I was also responsible for maintaining the application’s CI/CD pipeline and ensuring that the application was always up and running.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/windows-icon.png',
    },
    {
        organization: 'Microsoft',
        year: '2019',
        month: 'August',
        title: 'Software Engineer (Contract)',
        description: 'I was a part of the CSS Engineering (Customer Service & Support) team. We are a team of developers in charge of maintaining and distributing the PVA (Power Virtual Agent) application. The application spans a range of different technologies including .NET Core, JS/Typescript, React, Redux, Azure Services and much more.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/windows-icon.png',
    },
    {
        organization: 'Ekos',
        year: '2015',
        month: 'Jan',
        title: 'Software Developer',
        description: 'I worked as a full stack developer in a very fast paced development environment. I have built and helped maintain integral parts of the Ekos platform including data import and export functionality, payment/credit functionality, and UI functionality. I work first hand with our support team as well utilizing high product knowledge to debug any issues.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/ekos_icon.png',
    },
    {
        organization: 'Automated Conveyor Systems',
        year: '2012',
        month: 'June',
        title: 'Computer Systems Engineer',
        description: 'As a systems engineer I worked with our electrical engineers to produce high functioning code that controlled automated machines from conveyor belts to large automated transfer carts. My job included multiple on site visits to locations around the country where our product was tested and implemented. Most of the code I wrote was in C++ or SQL with very little UI influence. Aside from development, designed networking blueprints that were laid out in very large plants and worked first hand with executives of different companies like Georgia Pacific and Rock Tenn to customize projects for their specific locations.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/acs_icon.png',
    },
    {
        organization: 'University Of Lynchburg',
        year: '2009',
        month: 'January',
        title: 'Assistant Web Developer',
        description: 'It was my job as a junior developer to help with different parts of maintaining the school website. My tasks here were small but beneficial preparing my mind for work outside of school. A lot of what I did was routine updates to different parts of the website like the breakfast, lunch and dinner menu through XML as well as adding any new information about upcoming events for the week.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/lynchburg_icon.png',
    },
];
export var PROJECTS = [
    {
        title: 'Token Quotas Online Token Counter & Optimizer',
        description: 'As I jump into more projects dealing with AI and machine learning, I decided to create a tool that would help me calculate token counts for different language models and allow me to optimize them.',
        link: 'https://tokenquotas.com',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_token_quotas.png',
        tags: ['React', 'Typescript', 'WordPress', 'Google AdSense'],
    },
    {
        title: 'Copilot Memory (2024 Global Hackathon)',
        description: "I entered the 2024 Microsoft Global Hackathon with a team of engineers across the company to develop an extension for Microsoft's Copilot that allows users to simply save and recall memories.",
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_copilot_memory.png',
        tags: ['Copilot', 'Azure OpenAI', 'React', 'Typescript', 'Python'],
    },
    {
        title: 'Tech Navigator Mentorship Program',
        description: "The Tech Navigator program was birthed out of a necessity to have a structured mentorship and internship opportunity for kids interested in coding at my daughter's school. ",
        link: 'https://mytechnavigator.com/',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_tech_navigator.png',
        tags: ['Canva', 'WordPress', 'MySQL'],
    },
    {
        title: 'Fiscal Year Calculator Extension',
        description: 'Because I dealt with, sometimes confusing, fiscal year calculations a lot in my role at Microsoft, I decided to create a Chrome extension that would allow me to do it a little faster.',
        link: 'https://chromewebstore.google.com/detail/fiscal-date-calculator/ggmdcelfnfdhicjoemepffnbchcdlbaf',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thubnail-fiscalyear-calculator.png',
        tags: ['Chrome Web Store', 'Firebase', 'React', 'Typescript'],
    },
    {
        title: 'What Time Is It Extension',
        description: 'I decided to build a tool that, not only helped me collaborate a bit easier with colleagues across timezones, but helped me troubleshoot our applications when issues arose from users across timezones.',
        link: 'https://chromewebstore.google.com/detail/what-time-is-it/hnmbapkgnkbobhflmilhhfiponblelof',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail-what-time-is-it.png',
        tags: ['Chrome Web Store', 'Firebase', 'React', 'Typescript'],
    },
    {
        title: 'Relativ.ai',
        description: 'I was approached by a friend to help him with a project to facilitate screening interviews with a vitual AI interviewer. I setup the infrastructure and wrote the frontend UI, backend API and Python API to connect to OpenAI.',
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_relative_ai.png',
        tags: ['.Net Core', 'GraphQL', 'OpenAI', 'React', 'Typescript', 'Python'],
    },
    {
        title: 'Virtual Agent Project (Microsoft)',
        description: 'The Microsoft team I was on was tasked with taking on the Skylight project from another team in Redmond.  Although I was new to the company as a contractor, I decided to take on the work to develop the initial project UI infrastructure that was the base for entire frontned.',
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_virtual_agent.png',
        tags: ['Typescript', 'Azure', 'Redux', '.Net', 'React'],
    },
    {
        title: 'Codefront Technologies LLC',
        description: 'I love what I do and have been doing it for a while, learning a lot along the way. Codefront Technologies LLC is the company I started as an outlet for me to create apps, websites and extensions under an official capacity and help others bring their visions to life.',
        link: 'https://www.codefronttechnologies.com',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_codefront.png',
        tags: ['WordPress', 'Divi', 'Javascript', 'Typescript', 'React'],
    },
    {
        title: 'Auto Deployment Tool (Ekos)',
        description: 'Because most of our resources were on prem and I did a lot of the production deployments manually at the time, I created a tool that packaged all of the production DLL files and automatically deploy them to the chosen environment web server.',
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_ekos.png',
        tags: ['.Net', 'SQL', 'Powershell'],
    },
    {
        title: 'Capture Quote Mobile App',
        description: "As I read books and listen to podcasts, I'm often inspired to write down or highlight certain quotes that resonate with me. I decided to create a mobile app that would allow me to capture and organize these quotes in one place so I can recall them later.",
        link: 'https://capturequote.com/',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_capture_quote.png',
        tags: ['React Native', 'Firebase', '.Net Core', 'PostgreSQL', 'Azure'],
    },
    {
        title: 'Huddle Maps Web App',
        description: 'Huddle Maps was an idea that came out of struggling to find a place to meet with my friends who live around town.  It is a web app that allows users to collaborate together and choose, and vote on, places they want to meet up that is convenienet for everyone.',
        link: 'https://huddlemaps.com/',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_huddlemaps.png',
        tags: ['React', 'Typescript', 'Firebase Functions', 'Firestore DB'],
    },
];
export var LIFE_EVENTS = [
    {
        organization: 'Little Kid',
        year: '1998',
        month: 'January',
        title: 'Software Engineer II',
        description: 'I was promoted to Software Engineer II after demonstrating strong technical skills and leadership qualities. Led the development of a new feature for the company’s flagship product, collaborating with cross-functional teams to deliver high-quality software on schedule. Received positive feedback from stakeholders and customers for the successful launch of the feature.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/windows-icon.png',
    },
];
